<template>
  <!-- 预付费配置--查看 -->
  <div class="dataMigration-wrapper">
    <!--主体内容-->
    <div class="content">
      <div class="topback">
        <el-button @click="back" size="medium" type="primary">返回</el-button>
      </div>

      <el-form
        :inline="true"
        label-position="right"
        :model="formInline"
        ref="form"
        class="demo-form-inline"
      >
        <el-form-item prop="preChargeName" label="配置名称：">
         <div style="width:207px;">{{formInline.preChargeName}}</div>
          
        </el-form-item>
        <el-form-item prop="operationId" label="所属商户：">
          <el-select disabled v-model.trim="formInline.operationId" filterable size="15">
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
              v-for="value in tenantList"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="contentImtem">
        <span class="tipicon"></span><b class="tiptitle">预付费端口</b>
        <div class="padding16">
          <el-checkbox-group disabled v-model="formInline.preChargeDevice">
            <el-checkbox label="PDA"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <!-- <div class="contentImtem">
        <span class="tipicon"></span><b class="tiptitle">收费规则</b>
        <div class="padding16">
          <el-radio-group disabled v-model="formInline.preChargeRuleType">
            <el-radio :label="'1'">按时计费</el-radio>
            <el-radio :label="'2'">固定金额</el-radio>
          </el-radio-group>
        </div>
        <p class="tipdetail">
          配置PDA端按时间模式进行预收费，查看<span
            class="blueColor"
            @click="dialogVisible = true"
            >PDA端呈现效果图</span
          >。
        </p>
      </div> -->
      <div class="contentImtem" v-if="formInline.preChargeRuleType == 2">
        <span class="tipicon"></span><b class="tiptitle">预付费金额配置</b>
        <div class="padding16">
          <el-input
          disabled
            class="width30"
            size="small"
            v-model="formInline.preChargeRuleContent.level1"
            placeholder="未配置"
          ></el-input>
          <el-input
          disabled
            class="width30 padding020"
            size="small"
            v-model="formInline.preChargeRuleContent.level2"
            placeholder="未配置"
          ></el-input>
          <el-input
          disabled
            class="width30"
            size="small"
            v-model="formInline.preChargeRuleContent.level3"
            placeholder="未配置"
          ></el-input>
        </div>
        <div class="padding16">
          <el-checkbox disabled v-model="formInline.preChargeRuleContent.custom"
            >支持手动输入</el-checkbox
          >
        </div>
      </div>

      <div v-else class="contentImtem">
        <span class="tipicon"></span><b class="tiptitle">预付费提醒</b>
        <div class="padding16">
          <el-checkbox disabled v-model="formInline.preChargeNotice"
            >余额不足提醒</el-checkbox
          >
          <span class="tipwarn"
            >当余额小于等于1个计费单元的费用时，触发以短信形式发送车主</span
          >
        </div>
        <el-input
        disabled
          style="padding-left: 16px; width: 80%"
          type="textarea"
          :rows="2"
          placeholder=""
          v-model="formInline.preChargeNoticeContent"
        >
        </el-input>
      </div>

     
    </div>
    <!--按时计费 效果图 -->
    <el-dialog
      title="预收车费"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div class="billingon" v-if="formInline.preChargeRuleType == 1">
        <b>20</b>元
        <p class="tipdetail">选择停车时长，会自动计算预付金额</p>
        <div>
          停车时长：<el-input
            v-model="input"
            style="width: 210px"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="billingon" v-if="formInline.preChargeRuleType == 2">
       <div class="padding16">
          <el-input
            class="width30 "
            size="small"
            v-model="input"
            placeholder="未配置"
          ></el-input>
          <el-input
            class="width30 padding16"
            size="small"
            v-model="input"
            placeholder="未配置"
          ></el-input>
          <el-input
            class="width30"
            size="small"
            v-model="input"
            placeholder="未配置"
          ></el-input>
        </div>
        <div>
          其他金额：<el-input
            v-model="input"
            style="width: 210px;padding: 0 10px;"
            placeholder="请输入"
          ></el-input>元
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >预收车费</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "prepaidDetail",
  data() {
    return {
      input: '',
      preChargeSettingId: '',
      type: "detail",
      tenantList: [],
      dialogVisible: false,
      formInline: {
        preChargeName: "", // 预付费配置名称
        operationId: "", // 运营商ID
        preChargeDevice: "", // 预付端口 1 = PDA
        preChargeRuleType: "", // 收费规则 1 = 按时2 = 按钱
        preChargeRuleContent: {
          value1: '',
          value2: '',
          value3: '',
          custom: ''
        }, // 计费规则JSON内容
        preChargeNotice: "", // 是否提醒 1 = 提醒 0 = 不提醒
        preChargeNoticeContent: "", // 提醒内容,

        custom: '',  // 支持手工输入  1支持  0不支持
      }
    };
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    console.log(this.$route, "route111");
    this.type = this.$route.query.type ? this.$route.query.type : this.type;
    this.preChargeSettingId = this.$route.query.preChargeSettingId ? this.$route.query.preChargeSettingId : '';
   

   
      this.searchData()
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    // 查询
    searchData() {
      this.$axios
        .get("/acb/2.0/preChargeSetting/getById", {data: {
          preChargeSettingId: this.preChargeSettingId
        }})
        .then((res) => {
          if (res.state === 0) {
            this.formInline = res.value;
            
            this.formInline.preChargeDevice = this.formInline.preChargeDevice == '1';
            this.formInline.preChargeNotice = this.formInline.preChargeNotice == '1';
           
            let preChargeRuleContent = JSON.parse(res.value.preChargeRuleContent)
           
           if (this.formInline.preChargeRuleContent) {
              this.formInline.preChargeRuleContent.custom = this.formInline.preChargeRuleContent.custom == '1';
           }
            if (this.formInline.preChargeRuleType == "2") {
              preChargeRuleContent.custom = preChargeRuleContent.custom ? "1" : "0";
              preChargeRuleContent.level1 = preChargeRuleContent.level1 ? preChargeRuleContent.level1 * 100 : '';
              preChargeRuleContent.level2 = preChargeRuleContent.level2 ? preChargeRuleContent.level2 * 100 : '';
              preChargeRuleContent.level3 = preChargeRuleContent.level3 ? preChargeRuleContent.level3 * 100 : '';
            } else {
              preChargeRuleContent = null;
            }
          }
        });
    },
  },
};
</script>
<style lang="stylus">
.dataMigration-wrapper {
  background: #F4F7F9;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.padding16 {
    padding: 16px;
  }

  .width30 {
    width: 30%;
  }
.content {
  padding: 0 24px;

  

  .padding020 {
    padding: 0 20px;
  }

  .topback {
    margin: 16px 0;
    text-align: right;
  }

  .demo-form-inline {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px 55px 0;
  }

  .save {
    padding-top: 16px;
    text-align: center;
  }

  .contentImtem {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;

    .tipicon {
      width: 4px;
      height: 15px;
      background: #0C66FF;
      display: inline-block;
    }

    .tiptitle {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2F2F51;
      line-height: 20px;
      padding-left: 16px;
    }

    .tipdetail {
      padding-left: 16px;
      font-size: 12px;

      .blueColor {
        color: rgba(12, 102, 255, 1);
        cursor: pointer;
      }
    }

    .tipwarn {
      padding-left: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }
  }
}

.billingon {
  text-align: center;

  .tipdetail {
    font-size: 12px;
    padding: 10px 0 80px;
  }
}
</style>
